import { graphQlCall, GQLConstants } from "../Utils/GraphQLUtils";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from "Utils/GeneralUtils";

export const updateProfileApiCall = async (payload: any) => {
  const profilePayload = {
    profile: { ...payload }
  };

  try {
    const profileDetails = await graphQlCall(
      GQLConstants.MUTATION,
      GQLConstantMapping[getClientName()].mutations.UPDATE_PROFILE,
      profilePayload
    );
    return profileDetails;
  } catch (e) {
    console.error(e);
  }
};
