import React, { useState, CSSProperties, useEffect } from "react";
import { useStyles } from "./styles";
import { connect } from "react-redux";
import { OfferCard } from "gce-cxinteracts-ui-components-library";
import ClaimedOffers, {
  IClaimedOffers,
  ClaimedOffer
} from "Models/ReduxModels/ClaimedOffersModel";
import Offers, {
  IOffers,
  ImageClassifier,
  Offer
} from "Models/ReduxModels/OffersModel";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import {
  getOfferImage,
  getOfferBadgeDetails,
  isOfferCodeExpired,
  isOfferReclaimable,
  isOfferShared,
  checkIfOfferIsClickable,
  getLatestClaimDetailsByClaimId,
  gamificatioOffer
} from "Utils/offerUtils";
import { Typography } from "@material-ui/core";
import {
  getImageDetails,
  getValueFromHashMapArray,
  getClientName,
  previousPages,
  loadEvents
} from "Utils/GeneralUtils";
import { useHistory } from "react-router-dom";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { format } from "date-fns";
import PreClaimDialog from "Routes/OfferDetails/PreClaimDialog";
import { PreClaimDialogTypes } from "Models/Interfaces/PreClaimDialogTypes";
import OfferActions from "Components/ActionsComponent/ActionsComponent";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import SortedClaimedIds, {
  ClaimIds
} from "Models/ReduxModels/SortedClaimedIds";
import { useTheme } from "@material-ui/core";
import { OfferType } from "Models/Interfaces/OfferType";
import { truncate } from "lodash";
import { ImagesMapping } from "Configurations/ImagesMapping";
interface IProps {
  offers: IOffers;
  claimedOfferDetails: IClaimedOffers;
  merchants: IMerchant;
  sortedClaimedIds: ClaimIds;
  applicationFlags: IApplication;
}
const ClaimedOffersComponent: React.FC<IProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    offers,
    claimedOfferDetails,
    merchants,
    sortedClaimedIds,
    applicationFlags
  } = props;
  let claimedOffers: any[] = sortedClaimedIds?.claimIds;
  const clientName = getClientName();
  const [openDialog, setOpenDialog] = useState(false);
  let offerTitle = "";

  const cardTypes = {
    eatLocal: "Eat Local",
    coffeeClub: "Coffee Club"
  };

  useEffect(() => {
    let page_name = ConstantMapping[clientName].TEALIUM_MANUAL_ADD_MYCODES_PAGE;
    const state = {
      onLoadParams: {
        page_name
      }
    };
    loadEvents(previousPages, { state });
  }, []);

  const showOverlay = (
    offer: Offer,
    claimId: number,
    isExpiredCode: boolean
  ) => {
    let sharedOn: any = showPropertyResult(claimId, "sharedOn");
    // getValueFromHashMapArray(
    //   claimedOfferDetails?.[claimId]?.attributes,
    //   "sharedOn"
    // );
    let showImage = true;
    //  sharedOn = "2090-09-24T22:51:00Z";
    const imageDetails = getOfferImage(
      ImageClassifier.OFFER_IMAGE_LARGE,
      offer?.images
    );
    let overlayStyle: CSSProperties | undefined = undefined;
    if (sharedOn) {
      overlayStyle = {
        backgroundImage:
          "linear-gradient(rgba(232, 0, 0, 0.85), rgba(232, 0, 0, 0.85)), url('" +
          imageDetails?.url +
          "')"
      };
      if (isExpiredCode) {
        sharedOn = "The offer you gifted has expired";
      } else {
        sharedOn =
          "Gifted on " + format(new Date(sharedOn), "dd MMM, yyyy HH:mm");
      }
    } else if (isExpiredCode) {
      sharedOn = "Your voucher code has expired";
      showImage = false;
      overlayStyle = {
        background: `linear-gradient(rgba(100, 100, 100, 0.9), rgba(100, 100, 100, 0.9)) 0% 0% / calc(100% + 20px), url(${imageDetails?.url}) -10px -10px / calc(100% + 20px) no-repeat rgb(255, 255, 255)`
      };
    }

    return (
      <div
        aria-label={imageDetails?.altText}
        className={classes.sharedBackgroundImage}
        style={overlayStyle ? overlayStyle : {}}
      >
        {showImage && (
          <img
            alt="giftIcon"
            className={classes.sharedOverlayImage}
            src={ImagesMapping[clientName].iconGiftOpenedWhite}
          ></img>
        )}
        {!showImage && <div className={classes.sharedOverlayImage}></div>}

        {sharedOn && (
          <Typography className={classes.sharedOnText}>{sharedOn}</Typography>
        )}
      </div>
    );
  };

  const showPropertyResult = (claimId: number, property: string) => {
    let claimedOffer = claimedOfferDetails?.[claimId];
    return getValueFromHashMapArray(claimedOffer?.attributes, property);
  };

  const showOverlayStrip = (offer: Offer, claimId: number) => {
    // let claimedOffer = claimedOfferDetails?.[claimId];
    const imageDetails = getOfferImage(
      ImageClassifier.OFFER_IMAGE_LARGE,
      offer?.images
    );
    let result: any = showPropertyResult(claimId, "InstantWin");
    let stripText =
      result === "win"
        ? ConstantMapping[clientName].STRIP_INFO_IW_WIN
        : ConstantMapping[clientName].STRIP_INFO_IW_LOOSE;

    return (
      <div
        aria-label={imageDetails?.altText}
        className={classes.sharedBackgroundImage}
        style={{ backgroundImage: `url(${imageDetails?.url})` }}
      >
        <Typography
          className={classes.overlayStripText}
          variant={"h6"}
          style={{
            backgroundColor:
              result === "win" ? "rgba(230, 0, 0)" : "rgba(51, 51, 51)"
          }}
        >
          {stripText}
        </Typography>
      </div>
    );
  };

  const getClaimedOfferBadge = (
    offer: Offer,
    isShared: boolean,
    isExpiredCode: boolean,
    claimedId?: number,
    emailCapture: boolean = false,
    instantWinResult?: string
  ) => {
    let id = claimedId ?? 0;
    let claimedData = claimedOfferDetails[id];
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimedData?.attributes,
      "gamificationStatus"
    );
    let isCustomVendor = ["Coffee Club", "Eat Local"].includes(
      offer?.addType || ""
    );
    if (isCustomVendor && isExpiredCode) {
      return getOfferBadgeDetails(offer, "medium", claimedId);
    }
    if (
      !isShared &&
      !isExpiredCode &&
      !offer.shareable &&
      offer?.offerType !== OfferType.INSTANT_WIN
    ) {
      return getOfferBadgeDetails(
        offer,
        "medium",
        claimedId,
        gamificationShowEmailPopup
      );
    }
    if (
      ((offer?.offerType === OfferType.INSTANT_WIN &&
        instantWinResult === "win") ||
        offer?.offerType === OfferType.LUCKY_DIP) &&
      emailCapture
    ) {
      return getOfferBadgeDetails(
        offer,
        "medium",
        claimedId,
        gamificationShowEmailPopup
      );
    }
    return undefined;
  };

  const displayOffer = claimedId => {
    const InstantWinResult = showPropertyResult(claimedId, "InstantWin");
    if (InstantWinResult == "lose") {
      return false;
    }
    return true;
  };

  const renderOfferById = (claimedOffer: ClaimedOffer) => {
    const { offerId, claimedId } = claimedOffer;
    let offer = offers && offers[offerId];
    offerTitle = offer?.title;
    if (!offer) {
      return <div key={offerId}></div>;
    }
    const isExpiredCode = isOfferCodeExpired(offer, claimedId);
    const isShared = isOfferShared(offer, claimedId);
    const isReclaimable = isOfferReclaimable(offer, claimedId);
    const isOfferClickable = checkIfOfferIsClickable(offer);
    let claimedDetails = getLatestClaimDetailsByClaimId(
      offer?.claimedIds,
      claimedOfferDetails
    );
    let showOfferOnly = displayOffer(claimedId);
    let emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;
    if (claimedDetails) {
      let checkGamificationStatus = getValueFromHashMapArray(
        claimedDetails?.attributes,
        "gamificationStatus"
      );
      if (
        checkGamificationStatus === "SHOW_POPUP" ||
        checkGamificationStatus === "DETAILS_SUBMITTED" ||
        checkGamificationStatus === "EXPIRED"
      ) {
        emailCapture = true;
      }
    }

    let instantWinResult = getValueFromHashMapArray(
      claimedDetails?.attributes,
      "InstantWin"
    );

    return (
      <>
        {showOfferOnly && (
          <div
            key={offerId + claimedId}
            style={{ width: "calc(100% - 5vw)", marginBottom: "16px" }}
          >
            <OfferCard
              mode="carousel"
              imageDetails={getOfferImage(
                ImageClassifier.OFFER_IMAGE_LARGE,
                offer?.images
              )}
              logoDetails={getImageDetails(
                merchants[offer?.merchantId]?.imageUrl,
                ""
              )}
              showFavorites={false}
              title={offer?.title}
              isFavorite={false}
              onClick={e => {
                if (isReclaimable || !isExpiredCode) {
                  const isCustomVendorOffer =
                    offer?.addType === cardTypes.eatLocal ||
                    offer?.addType === cardTypes.coffeeClub;
                  if (isCustomVendorOffer) {
                    history.push(`/custom-vendor/${offerId}`);
                  } else if (isOfferClickable && !isExpiredCode) {
                    history.push(`/offer-details/${offerId}`);
                  }
                  new ApplicationFlag({
                    ...applicationFlags,
                    id: "",
                    isShowFooter: true,
                    isMyCodesActive: true
                  }).$save();
                }
              }}
              onActionClicked={() => {
                if (claimedDetails && offer?.offerType === OfferType.BANNER) {
                  new ApplicationFlag({
                    id: "",
                    ...applicationFlags,
                    isShowBannerPopup: isExpiredCode ? false : true,
                    isMyCodesActive: true
                  }).$save();
                  history.push(`/custom-vendor/${offerId}`);
                } else {
                  if (isReclaimable || !isExpiredCode) {
                    if (isShared) {
                      setOpenDialog(true);
                    } else {
                      new ApplicationFlag({
                        id: "",
                        ...applicationFlags,
                        isShowFooter: true,
                        isMyCodesActive: true
                      }).$save();
                      if (gamificatioOffer(offer, claimedOffer)) {
                        history.push(`/offer-details/${offer.offerId}`);
                      } else {
                        history.push(`/code/${claimedId}`);
                      }
                    }
                  }
                }
              }}
              style={{
                boxShadow: theme.shadows[2],
                height: "calc(45vw + 150px)",
                color: theme.palette.secondary.light
              }}
              options={{
                cardActionAltText: {
                  saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
                  unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
                },
                overrideComponent: {
                  OfferImage:
                    emailCapture !== true
                      ? isShared ||
                        (isExpiredCode &&
                          !["Coffee Club", "Eat Local"].includes(
                            offer?.addType || ""
                          ))
                        ? () => {
                            return showOverlay(offer, claimedId, isExpiredCode);
                          }
                        : offer.offerType === OfferType.INSTANT_WIN
                        ? () => showOverlayStrip(offer, claimedId)
                        : undefined
                      : undefined,
                  ActionElement: () => {
                    return (
                      <OfferActions
                        mode="Voucher"
                        offer={offer}
                        claimedId={claimedId}
                        claimedOffer={claimedDetails}
                      ></OfferActions>
                    );
                  }
                }
              }}
              badge={getClaimedOfferBadge(
                offer,
                isShared,
                isExpiredCode,
                claimedId,
                emailCapture,
                instantWinResult
              )}
            ></OfferCard>
          </div>
        )}
      </>
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const giftHelpAction = () => {
    new ApplicationFlag({
      id: "",
      isShowFooter: true,
      isMyCodesActive: true
    }).$save();
    history.push("/chat");
  };

  //Need to changw this after actual API response
  let updatedClaimedOffers: any[] = [];
  {
    claimedOffers &&
      claimedOffers.map((claimedId: number) => {
        let id = claimedOfferDetails[claimedId]?.offerId;
        let offerData = offers && offers[id];
        if (offerData?.offerType === OfferType.BANNER) {
          updatedClaimedOffers.unshift(claimedId);
        } else {
          updatedClaimedOffers.push(claimedId);
        }
      });
  }

  return (
    <div className={classes.claimedOffersMainContainer}>
      <PreClaimDialog
        openDialog={openDialog}
        type={PreClaimDialogTypes.GIFTHELP}
        claimAdvice={ConstantMapping[clientName].GIFT_HELP_ADVICE}
        clientName={clientName}
        handleCloseDialog={handleCloseDialog}
        confirmCallback={giftHelpAction}
        title={offerTitle}
      />
      {updatedClaimedOffers &&
        updatedClaimedOffers.map((claimedId: number) => {
          return renderOfferById(claimedOfferDetails[claimedId]);
        })}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const offers = Offers.getInsatnce("", state)?.props;
  let claimedOfferDetails = ClaimedOffers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const sortedClaimedIds = SortedClaimedIds.getInsatnce("", state)?.props;
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    offers,
    claimedOfferDetails,
    merchants,
    sortedClaimedIds,
    applicationFlags
  };
};
export default connect(mapStateToProps)(ClaimedOffersComponent);
