import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column"
    },
    eatLocalImage: {
      height: "50vw",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0 0",
      backgroundSize: "cover"
    },
    eatLocalContentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    mainWrapper: {
      color: theme.palette.common.black
    },
    content: {
      display: "flex",
      alignItems: "center",
      fontFamily: theme.typography.fontFamily,
      lineHeight: "24px"
    },
    contentHeading: {
      fontSize: theme.typography.subtitle1.fontSize,
      margin: theme.spacing(0),
      fontFamily: theme.typography.fontFamily
    },
    closeButton: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      position: "absolute",
      padding: theme.spacing(2)
    },
    closeIcon: {
      height: theme.spacing(4.5)
    },
    gourmetImageWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(4)
    },
    gourmetImage: {
      height: theme.spacing(10),
      width: "50%"
    },
    timerContainer: {
      display: "flex",
      width: "200px",
      flexDirection: "row",
      justifyContent: "center",
      background: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: "40px",
      padding: "4px"
    },
    timer: {
      boxSizing: "border-box",
      zIndex: 3,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      marginTop: "-21px"
    },
    timerText: {
      fontFamily: "VodafoneRgBold",
      fontSize: "30px"
    },
    delimiter: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    successMessage: {
      backgroundColor: "green",
      color: "white",
      padding: "10px",
      display: "flex"
    },
    tickIcon: {
      height: "18px"
    },
    successMessageText: {
      marginLeft: "8px"
    }
  })
);
