import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Svg } from "gce-cxinteracts-ui-components-library";
import { connect } from "react-redux";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import { useStyles } from "./styles";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { iOSDevice } from "Utils/GeneralUtils";
import { getClientName } from "Utils/GeneralUtils";
import Scrolls from "Models/ReduxModels/ScrollModel";
import AppConfig from "Models/ReduxModels/AppConfig";
interface Props extends RouteComponentProps {
  favOfferData: IFavouriteOffers;
  referalConfig?: any;
}

const PrimaryNav: React.FC<Props> = props => {
  let pathMap = ["/home", "/myRewards", "/referfriend", "/myProfile", "/help"];
  const MY_REWARDS_LABEL = "My Rewards";
  const history = useHistory();
  const classes = useStyles();
  const { favOfferData } = props;
  const clientName = getClientName();
  const [value, setValue] = useState(0);
  const [animateRewards, setAnimateRewards] = useState(false);
  const [favOffers, setFavOffers] = useState<string[]>([]);
  const [footerPadding, setFooterPadding] = useState("0px");
  let footerVodafoneIcons: any = ConstantMapping[clientName].SVG.footerIcons;
  const rootElement: any = document.getElementById("root");
  const fixedMainElement: any = document.getElementById("main-container");

  useEffect(() => {
    const { pathname } = props.location;
    const value = pathMap.indexOf(pathname);
    if (value > -1) {
      setValue(value);
    }
  }, [props]);

  useEffect(() => {
    if (rootElement) {
      if (iOSDevice() && rootElement.offsetHeight > 700) {
        setFooterPadding("10px");
      } else {
        setFooterPadding("0px");
      }
    }
  }, []);

  useEffect(() => {
    animateOnFavAdd();
  }, [favOfferData]);

  const animateOnFavAdd = () => {
    const favOfferIds = Object.keys(favOfferData || {});

    if (!favOffers.length) {
      setFavOffers(favOfferIds || []);
    }
    if (favOffers.length && favOfferIds.length > favOffers.length) {
      setAnimateRewards(true);
      setTimeout(stopAnimation, 2000);
    }
    setFavOffers(favOfferIds);
  };

  const stopAnimation = () => {
    setAnimateRewards(false);
  };

  const resetScroll = () => {
    if (fixedMainElement) {
      fixedMainElement.scrollTop = 0;
    }
  };

  const handleChange = (event: any, value: number) => {
    new Scrolls({
      id: "",
      scrollCurrentPosition: 0
    }).$save();
    resetScroll();
    history.push(pathMap[value]);
    setValue(value);
  };

  const handleRewardPath = (data: any) => {
    if (animateRewards) {
      return data?.selected?.path;
    } else {
      return handleFooterSvgPath(data);
    }
  };

  const handleFooterSvgPath = (data: any) => {
    if (data?.id === value) {
      return data?.selected?.path;
    } else {
      return data?.path;
    }
  };

  const renderIcons = (data: any) => {
    const styles = {
      width: data?.width,
      height: data?.height,
      style: data?.style,
      viewBox: data?.viewBox,
      stroke: data?.stroke,
      strokeWidth: data?.strokeWidth,
      className:
        data.label === MY_REWARDS_LABEL && animateRewards
          ? classes.startAnimation
          : ""
    };

    return (
      <Svg
        svgStyleProps={styles}
        isSvg={true}
        transform={data?.transform}
        path={
          data?.label === MY_REWARDS_LABEL
            ? handleRewardPath(data)
            : handleFooterSvgPath(data)
        }
        fill={data?.id === value ? data?.selected?.fill : data?.fill}
        fillRule={data.fillRule ? data.fillRule : ""}
      />
    );
  };

  const renderNavigation = (footerIcon: any) => {
    return (
      <BottomNavigationAction
        key={footerIcon?.label}
        label={footerIcon?.label}
        icon={renderIcons(footerIcon)}
        aria-label={footerIcon?.ariaLabel}
      />
    );
  };

  const renderBottomNavigationAction = () => {
    let iconList = Object.values(footerVodafoneIcons);
    const { referalConfig } = props;
    let enableReferalJourney: any;
    referalConfig?.value.forEach(item => {
      if (item.name === "enable-referral-program") {
        enableReferalJourney = item.value;
      }
    });

    return iconList.map((item: any) => {
      if (enableReferalJourney === "false" && item.label === "Refer a friend") {
        return null;
      } else {
        return renderNavigation(item);
      }
    });
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
      style={{ paddingBottom: footerPadding, boxSizing: "content-box" }}
    >
      {renderBottomNavigationAction()}
    </BottomNavigation>
  );
};

const mapStateToProps = (state: any) => {
  const favOfferData = FavouriteOffers.getInsatnce("", state)?.props;
  let referalConfig = AppConfig.getInsatnce("referral-section")?.props;

  return {
    favOfferData,
    referalConfig
  };
};

export default connect(mapStateToProps)(withRouter(PrimaryNav));
