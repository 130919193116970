import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { useStyles as useStylesClaimed } from "../ClaimedOffersComponent/styles";
import { connect } from "react-redux";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import { OfferCard } from "gce-cxinteracts-ui-components-library";
import Offers, {
  IOffers,
  ImageClassifier,
  Offer
} from "Models/ReduxModels/OffersModel";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { removeFavouriteOffers } from "Services/RemoveFavouriteService";
import {
  getOfferImage,
  isFavoriteOffer,
  getOverlayIcon,
  getOfferBadgeDetails,
  isOfferLocked,
  isOfferCodeValid,
  isOfferCodeExpired,
  checkIfOfferIsClickable,
  computeActions,
  getLatestClaimDetailsByClaimId,
  isOfferComingSoon,
  gamificatioOffer
} from "Utils/offerUtils";
import {
  getImageDetails,
  getValueFromHashMapArray,
  loadEvents,
  previousPages
} from "Utils/GeneralUtils";
import { useHistory } from "react-router-dom";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import OfferActions from "Components/ActionsComponent/ActionsComponent";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { Typography, useTheme } from "@material-ui/core";
import { OfferType } from "Models/Interfaces/OfferType";
import { Actions } from "Models/Interfaces/Actions";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import { getClientName } from "Utils/GeneralUtils";
interface IProps {
  favorites: IFavouriteOffers;
  offers: IOffers;
  merchants: IMerchant;
  favouriteOfferIds: OfferIds;
  claimedOffers: IClaimedOffers;
  applicationFlags: IApplication;
}

const FavouriteOffersComponent: React.FC<IProps> = props => {
  const classes = useStyles();
  const classesClaimed = useStylesClaimed();
  const history = useHistory();
  const theme = useTheme();
  const {
    offers,
    claimedOffers,
    favorites,
    merchants,
    favouriteOfferIds,
    applicationFlags
  } = props;

  let sortedFavouriteOffers: any[] = favouriteOfferIds?.offerIds;
  const clientName = getClientName();
  let isFavoriteCallIP = false;

  useEffect(() => {
    let page_name = `${ConstantMapping[clientName].TEALIUM_MANUAL_ADD_SAVE_PAGE}`;
    if (previousPages[previousPages.length - 1] === page_name) {
      return;
    }
    const state = {
      onLoadParams: {
        page_name
      }
    };
    loadEvents(previousPages, { state });
  }, []);

  const callRemoveFavouriteOffersApi = (offerId: any) => {
    if (!isFavoriteCallIP) {
      isFavoriteCallIP = true;
      let variable = { offerId: offerId };
      removeFavouriteOffers(variable, favorites, favouriteOfferIds)
        .then(() => {
          isFavoriteCallIP = false;
          // if (updateSaveUnsave_OfferCount() < 3) {
          //   manualParameterPass(previousPages, {
          //     show_view: false,
          //     event_name: ConstantMapping[clientName].TEALIUM_PAGE_OFFER_SAVE_UNSAVE,
          //     page_name: ConstantMapping[clientName].TEALIUM_MANUAL_ADD_SAVE_PAGE,
          //     same_page_event: true
          //   });
          // }
        })
        .catch(() => {
          isFavoriteCallIP = false;
        });
    }
  };

  const showOverlayStrip = (offer: Offer) => {
    const imageDetails = getOfferImage(
      ImageClassifier.OFFER_IMAGE_MEDIUM,
      offer?.images
    );
    let stripText =
      offer.instantWinResult === "win"
        ? ConstantMapping[clientName].STRIP_INFO_IW_WIN
        : ConstantMapping[clientName].STRIP_INFO_IW_LOOSE;
    return (
      <div
        aria-label={imageDetails?.altText}
        className={classesClaimed.sharedBackgroundImage}
        style={{ backgroundImage: `url(${imageDetails?.url})` }}
      >
        <Typography
          className={classesClaimed.overlayStripText}
          variant={"h6"}
          style={{
            backgroundColor:
              offer.instantWinResult === "win"
                ? "rgba(230, 0, 0)"
                : "rgba(51, 51, 51)"
          }}
        >
          {stripText}
        </Typography>
      </div>
    );
  };

  const isValidAction = offer => {
    let action = computeActions(offer);
    switch (action) {
      case "instant-win":
      case "use":
      case Actions.PRIZE_DRAW_ENTERED:
        return true;
    }
    return false;
  };

  const getTitle = (offer, comingSoonOffer) => {
    let title: any = "";
    if (comingSoonOffer) {
      if (offer?.comingSoonDetails?.title !== (null || "")) {
        title = offer?.comingSoonDetails?.title;
      } else {
        title = offer?.title;
      }
    } else {
      title = offer?.title;
    }
    return title;
  };

  const renderOfferById = (favOffer: any) => {
    const { offerId } = favOffer;
    let offer = offers && offers[offerId];
    if (!offer) {
      return <div key={offerId}></div>;
    }
    const claimDetails = getLatestClaimDetailsByClaimId(
      offer.claimedIds,
      claimedOffers
    );
    const claimedId = claimDetails?.claimedId;
    const isLocked = isOfferLocked(offer, false);
    const isValidCode = isOfferCodeValid(offer);
    const isOfferClickable = checkIfOfferIsClickable(offer);
    const showFavorites = !isValidAction(offer);
    const comingSoonOffer = isOfferComingSoon(offer?.startDateTime);
    let gamificationShowEmailPopup = getValueFromHashMapArray(
      claimDetails?.attributes,
      "gamificationStatus"
    );
    let emailCapture = offer?.redemptionDetails?.[0]?.captureEmail || false;
    if (
      gamificationShowEmailPopup === "SHOW_POPUP" ||
      gamificationShowEmailPopup === "DETAILS_SUBMITTED" ||
      gamificationShowEmailPopup === "EXPIRED"
    ) {
      emailCapture = true;
    }
    return (
      <div
        key={offerId}
        style={{ width: "calc(100% - 5vw)", marginBottom: "16px" }}
      >
        <OfferCard
          mode="carousel"
          imageDetails={getOfferImage(
            ImageClassifier.OFFER_IMAGE_LARGE,
            offer?.images
          )}
          logoDetails={getImageDetails(
            merchants[offer?.merchantId]?.imageUrl,
            ""
          )}
          showFavorites={showFavorites}
          title={getTitle(offer, comingSoonOffer)}
          isFavorite={isFavoriteOffer(offerId)}
          onClick={() => {
            if (!isOfferClickable) {
              return;
            }
            new ApplicationFlag({
              id: "",
              isShowFooter: true,
              isMyCodesActive: false
            }).$save();
            const isCustomVendorOffer =
              offer?.addType === "Eat Local" ||
              offer?.addType === "Coffee Club";
            if (isCustomVendorOffer) {
              new ApplicationFlag({
                id: "",
                ...applicationFlags,
                isShowBannerPopup: false
              }).$save();
              history.push(`/custom-vendor/${offerId}`);
            } else {
              history.push(`/offer-details/${offerId}`);
            }
          }}
          onActionClicked={() => {
            if (showFavorites && !offer?.isStoreGiftingOffer) {
              callRemoveFavouriteOffersApi(offerId);
            } else if (isOfferClickable && claimedId) {
              new ApplicationFlag({
                id: "",
                isShowFooter: true,
                isMyCodesActive: false
              }).$save();
              if (gamificatioOffer(offer, claimDetails)) {
                history.push(`/offer-details/${offer.offerId}`);
              } else {
                history.push(`/code/${claimedId}`);
              }
            }
          }}
          style={{
            boxShadow: theme.shadows[2],
            height: "calc(45vw + 150px)",
            color: theme.palette.secondary.light
          }}
          options={{
            cardActionAltText: {
              saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
              unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
            },
            overlayIcon:
              isLocked && !isValidCode ? getOverlayIcon(offer) : undefined,
            overrideComponent: {
              ActionElement: () => {
                return (
                  <OfferActions
                    mode="Carousel"
                    offer={offer}
                    claimedOffer={claimDetails}
                  ></OfferActions>
                );
              },
              OfferImage:
                 undefined
            },
            actionElementStyle: offer?.isStoreGiftingOffer
              ? {
                  fillColor: "#cccccc",
                  strokeColor: "#cccccc"
                }
              : undefined
          }}
          isBlurred={isLocked && !isValidCode}
          badge={getOfferBadgeDetails(
            offer,
            "medium",
            claimDetails?.claimedId,
            gamificationShowEmailPopup
          )}
        ></OfferCard>
      </div>
    );
  };

  return (
    <div className={classes.favOffersMainContainer}>
      {sortedFavouriteOffers?.length ? (
        sortedFavouriteOffers.map((offerId: any) => {
          return renderOfferById(favorites[offerId]);
        })
      ) : (
        <div className={classes.content}>
          {ConstantMapping[clientName].SAVED_OFFER_CONTENT}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const offers = Offers.getInsatnce("", state)?.props;
  const claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce(
    "",
    state
  )?.props;
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    offers,
    favorites,
    merchants,
    claimedOffers,
    favouriteOfferIds,
    applicationFlags
  };
};

export default connect(mapStateToProps)(FavouriteOffersComponent);
