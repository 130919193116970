import React, { useEffect } from "react";
import { getClientName } from "Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
import NewJourneyFramework from "../newJourneyFramework";
import { connect } from "react-redux";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import UserProfile from "../../../Models/ReduxModels/UserProfileModel";
import { logEvents } from "Services/EventService";
import { pushAllEventsToServer } from "Services/EventService";
import { EVENT_TYPE } from "Services/EventService";
import { getNextStartBoardingEvent } from "../../../Utils/GeneralUtils";
import { OnBoardingConstants } from "../constants";

interface IProps {
  history: History;
  clientData: any;
  userProfileData: any;
  currentOnboardingPage: number;
  totalOnboardingPages: Array<String>;
}
const WelcomeScreen: React.FC<IProps> = props => {
  let clientName = getClientName();
  const { userProfileData, currentOnboardingPage, totalOnboardingPages } =
    props;

  useEffect(() => {
    new UserProfile({ ...userProfileData, id: "local" }).$save();
    logEvents({
      eventType: EVENT_TYPE.ONBOARDING_START,
      permissions: JSON.stringify(userProfileData.permissions)
    });
    pushAllEventsToServer();
  }, []);

  const handlePermissionClick = () => {
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    const nextStartEvent=getNextStartBoardingEvent(currentOnboardingPage, totalOnboardingPages);
    if(nextStartEvent){
      logEvents({ eventType: nextStartEvent });
    }
    pushAllEventsToServer();
    nextPage();
  };
  const nextPage = () => {
    new UserProfile({
      id: "local",
      ...userProfileData,
      currentOnboardingPage: currentOnboardingPage + 1
    }).$save();
   
  };
  return (
    <>
      <NewJourneyFramework
        heading={OnBoardingConstants.WELCOME_HEADING}
        subHeading={OnBoardingConstants.WELCOME_SUBHEADING}
        handlePermissionClick={handlePermissionClick}
        buttonOkText="Get started"
        onboardingImage={ImagesMapping[clientName].newOnboardingStartImage}
        imageAltText="welcomeHeroImg"
        // buttonCancelText="Deny Notifications"
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const clientData = ClientModel.getInsatnce("", state)?.props;
  const userProfileData = UserProfile.getInsatnce("local", state)?.props;
  const currentOnboardingPage = userProfileData.currentOnboardingPage;
  const totalOnboardingPages = userProfileData?.totalOnboardingPages;

  return {
    clientData,
    userProfileData,
    currentOnboardingPage,
    totalOnboardingPages
  };
};

export default connect(mapStateToProps)(WelcomeScreen);
