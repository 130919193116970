import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      position: "relative",
      justifyContent: "space-around",
      alignItems: "center",
      background: theme.palette.primary.contrastText,
      fontFamily: theme.typography.fontFamily,
      "& .MuiBottomNavigationAction-root": {
        color: theme.palette.secondary.light,
        padding: "0px",
        minWidth: "70px",
        "& .MuiBottomNavigationAction-label.Mui-selected": {
          fontSize: "12px"
        }
      },
      "& .Mui-selected": {
        color: theme.palette.primary.main,
        paddingTop: "0px"
      }
    },
    startAnimation: {
      animation: "$enlargeIcon 2s forwards "
    },

    "@keyframes enlargeIcon": {
      "0%": {
        transform: "scale(1)"
      },
      "25%": {
        transform: "scale(1)",
        color: theme.palette.primary.main
      },
      "50%": {
        transform: "scale(1.5)",
        color: theme.palette.primary.main
      },
      "75%": {
        transform: "scale(1)"
      }
    }
  })
);
