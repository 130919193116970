import { BaseModel } from "ReduxStore/index";
import { OfferType } from "../Interfaces/OfferType";

export enum ImageClassifier {
  OFFER_IMAGE_LARGE = "OFFER_IMAGE_LARGE",
  OFFER_IMAGE_MEDIUM = "OFFER_IMAGE_MEDIUM",
  OFFER_IMAGE_SMALL = "OFFER_IMAGE_SMALL"
}
export enum RedemptionMethod {
  "AUTOMATIC",
  "CALL",
  "INSTORE",
  "INSTORE_OR_PRINT",
  "PRINT",
  "LINK",
  "ONLINE",
  "INSTORE_PRINT",
  "INSTORE_OR_ONLINE"
}
export enum RedemptionCodeType {
  "ALPHANUMERIC",
  "BARCODE_CODE_128",
  "BARCODE_EAN_128",
  "BARCODE_EAN_13",
  "BARCODE_EAN_8",
  "NONE",
  "QR",
  "URL",
  "BARCODE_GC_19"
}
export interface ImageDetails {
  url: string;
  altText: string; // alt text will be used as aria-label when we are using background image and as alt-text when we use image tag
  classifier: ImageClassifier;
}
export interface Image {
  [key: string]: ImageDetails;
}
export interface KeyValuePair {
  key: string;
  value: string;
}
export interface ClaimLimitDetails {
  total: boolean;
  notEnoughCodes: boolean;
  totalPerPeriod: boolean;
  user: boolean;
  userPerPeriod: string | null;
}
export interface RedemptionDetail {
  redemptionMethod: RedemptionMethod;
  redemptionType: RedemptionCodeType;
  url: string | null;
  guidelines: string | null;
  prizeName: string | null;
  captureEmail: boolean | null;
}

export interface OfferPrizeDetail {
  prizeName: string | null;
  voucherCodeExpiryModelType: string | null;
  voucherCodePresentation: string | null;
  voucherCodeExpiryModelValue: string | null;
}

export enum BadgeType {
  "DEFAULT",
  "OVERLAYSTRIP"
}

export enum RedemptionExpiryModel {
  "None",
  "DateTime",
  "Timer"
}

export interface ClaimConditionsFailedModel {
  dimension: string;
  subDimension: string;
  values: any[];
}

export interface ComingSoonDetailsModel {
  title: string | null;
  images: Image | null;
  shortTitle: string | null;
}

export interface OfferRatingModel {
  id: number | null;
  feedback: string | null;
  offerId: number | null;
  customerId: string | null;
  offerRating: number | null;
  createdOn: Date | null;
}

export interface Offer {
  offerId: number;
  title: string;
  titleShort: string;
  description: string;
  termsAndConditions: string;
  termsAndConditionsUrl: string;
  preClaimAdvice: string;
  teaser: string | null;
  carouselCardTextColor: string;
  keyTerms: string;
  entryMechanics: string;
  images: Image;
  redemptionDetails: RedemptionDetail[];
  redemptionExpiryModel: RedemptionExpiryModel;
  offerRating: OfferRatingModel;
  shareable: boolean;
  showPreClaimPopup: boolean;
  merchantId: number;
  startDateTime: Date;
  endDateTime: Date;
  claimLimitDetails: ClaimLimitDetails;
  claimConditionsFailed: ClaimConditionsFailedModel[] | null; //To Be modified in claim flow
  offerType: OfferType;
  callToActionDetails: KeyValuePair[];
  additionalDetails: KeyValuePair[];
  instantWinResult: string | null;
  gamificationEmailPopup: string | null;
  claimedIds: number[];
  addType?: string;
  showFullAddImage?: string | boolean;
  comingSoonDetails?: ComingSoonDetailsModel;
  giveToLiteUser: boolean;
  offerPrizeDetails: OfferPrizeDetail[] | null;
  isStoreGiftingOffer: boolean | null;
  isQRActive: boolean;
  isConvergedOffer?: boolean | null;
  captureEmail?: boolean | false;
  isReferralReward?: boolean;
}
export interface IOffers {
  [key: number]: Offer;
}
export default class Offers extends BaseModel<IOffers> {
  static resource = "Offers";
}
// export class OfferIds extends BaseModel<number[]> {
//   static resource = "OffersIds";
// }
