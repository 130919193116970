import React, { Fragment } from "react";
import { useStyles } from "./styles";
import { Dialog, Typography, Button } from "@material-ui/core";
import { ThemeMapping } from "Configurations/ThemeMapping";
import QRBarcode from "Components/QRBarcode/QRBarcode";
import Timer from "./Timer";
import { computeTicks, getCurrentTime } from "Utils/GeneralUtils";
import moment from "moment";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  openDialog: boolean;
  closeDialog: any;
  clientName: string;
  imageDetails: any;
  offerClaimedInfo: any;
  showSuccessMessage: boolean;
  isMembershipExpired: boolean;
  customOfferType: any;
}

const EatLocalPopup: React.FC<IProps> = props => {
  let classes = useStyles();
  const {
    openDialog,
    closeDialog,
    clientName,
    imageDetails,
    offerClaimedInfo,
    showSuccessMessage,
    isMembershipExpired,
    customOfferType
  } = props;
  const theme = ThemeMapping[clientName];

  const renderTimer = () => {
    return (
      <Timer
        remainingTicks={computeTicks(offerClaimedInfo?.expiryDateTime)}
        style={{
          boxSizing: "border-box",
          zIndex: 3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          marginTop: "-21px"
        }}
        styleOptions={{
          timerNumbersStyle: {
            fontFamily: "VodafoneRgBold",
            fontSize: "30px"
          }
        }}
      ></Timer>
    );
  };

  const renderTimerNow = () => {
    let d = moment(getCurrentTime());
    let hrs = d.hours();
    let minutes = d.minutes();
    let seconds = d.seconds();

    return (
      <div className={classes.timer}>{renderTime(hrs, minutes, seconds)}</div>
    );
  };

  const renderTime = (hours, minutes, seconds) => {
    return (
      <div className={classes.timerContainer}>
        <Typography variant="h1" className={classes.timerText}>
          {hours}
          <span className={classes.delimiter}>:</span>
        </Typography>
        <Typography variant="h1" className={classes.timerText}>
          {minutes}
          <span className={classes.delimiter}>:</span>
        </Typography>
        <Typography variant="h1" className={classes.timerText}>
          {seconds}
        </Typography>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <>
        <div
          className={classes.eatLocalImage}
          aria-label="eat-local"
          style={{
            backgroundImage: `url('${imageDetails?.url}')`
          }}
        />
      </>
    );
  };

  const renderCloseIcon = () => {
    return (
      <Button
        className={classes.closeButton}
        disableRipple
        disableFocusRipple
        startIcon={
          <div>
            <img
              src={ImagesMapping[clientName].iconCloseWhite}
              alt="close-icon"
              className={classes.closeIcon}
            />
          </div>
        }
        onClick={closeDialog}
      ></Button>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className={classes.successMessage}>
        <img
          src={ImagesMapping[clientName].tickIcon}
          alt="tick"
          className={classes.tickIcon}
        />
        <span className={classes.successMessageText}>
          {isMembershipExpired && !(customOfferType === "Coffee Club")
            ? "Great! Your Eat Local membership has been renewed."
            : customOfferType === "Coffee Club"
            ? isMembershipExpired
              ? "Great! Your Local Blend membership has been renewed."
              : "Great! Your Local Blend membership is now active."
            : "Great! Your Eat Local membership is now active."}
        </span>
      </div>
    );
  };

  const convertDate = isoDate => {
    return moment(isoDate).utc().format("DD/MM/YYYY");
  };

  const renderContent = () => {
    let customBottomLogo =
      customOfferType === "Coffee Club"
        ? ImagesMapping[clientName].coffeeClubLogoImage
        : ImagesMapping[clientName].eatLocalGourmetImage;
    return (
      <div className={classes.mainWrapper}>
        {renderCloseIcon()}
        {renderImage()}
        {showSuccessMessage ? renderSuccessMessage() : renderTimer()}

        <QRBarcode
          type={"QR"}
          value={offerClaimedInfo?.redemptionCode || ""}
          clientName={clientName}
        />
        <div className={classes.eatLocalContentWrapper}>
          <div className={classes.content}>
            <h2 className={classes.contentHeading}>Membership number:</h2>
            &nbsp; {offerClaimedInfo?.redemptionCode}
          </div>
          <div className={classes.content}>
            <h2 className={classes.contentHeading}>Valid: </h2> &nbsp;
            {convertDate(offerClaimedInfo?.claimDateTime)}&nbsp;-&nbsp;
            {convertDate(offerClaimedInfo?.expiryDateTime)}
          </div>
        </div>
        <div className={classes.gourmetImageWrapper}>
          <span>powered by</span>
          <img
            src={customBottomLogo}
            alt="gourmet-image"
            className={classes.gourmetImage}
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
            margin: theme.spacing(9.5),
            color: theme.palette.primary.contrastText
          }
        }}
        TransitionProps={{
          style: {
            background: "rgba(0, 0, 0, 0.4)"
          }
        }}
        disableBackdropClick={true}
      >
        {renderContent()}
      </Dialog>
    </Fragment>
  );
};

export default EatLocalPopup;
