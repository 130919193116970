import { makeStyles, Theme, createStyles } from "@material-ui/core";
import bgImage from "Configurations/Clients/vodafone/Assets/Images/ReferalAssets/referal_background.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      background: "linear-gradient(180deg, #F6F6F6 63.43%, #D9D8D8 100%)",
      display: "flex",
      flexDirection: "column"
    },
    mainWrapper: {
      background: "linear-gradient(180deg, #F6F6F6 63.43%, #D9D8D8 100%)",
      flex: 1,
      overflowY: "auto"
    },
    bgImgContainer: {
      backgroundImage: `url(${bgImage})`,
      borderRadius: "12px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "18px"
    },
    referContainer: {
      margin: "0vw 5vw",
      paddingTop: " 5vw",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
    },
    referHeading: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "#fff",
      "& h1": {
        margin: "16px 0px 6px 0px",
        flex: "1",
        lineHeight: "38px",
        letterSpacing: "1px"
      }
    },
    referGiftIcon: {
      width: "144px"
    },
    referalCountBox: {
      minHeight: "76px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      color: "#fff",
      border: "2px solid #fff",
      borderRadius: "12px",
      fontSize: "26px",
      fontWeight: "bolder",
      padding: "12px 16px"
    },
    referText: {
      fontSize: "18px"
    },
    shareText: {
      color: "#fff",
      fontSize: "30px",
      marginTop: "14px"
    },
    referSmallText: {
      fontSize: "22px",
      color: "#fff",
      margin: "8px 0px 20px 0px",
      fontWeight: "bold"
    },
    referalLinkButtonWrapper: {
      margin: "0vw 5vw",
      width: "54%",
      position: "sticky",
      bottom: "0px",
      display: "flex",
      justifyContent: "center",
      zIndex: 1000
    },
    referalLinkButton: {
      height: "54px",
      width: "100%",
      borderRadius: "16px",
      marginBottom: "10px",
      "& span": {
        textTransform: "none",
        fontSize: "22px",
        fontWeight: "bold"
      }
    },
    shareIconButton: {
      padding: "0px",
      marginBottom: "8px",
      "& .MuiButton-iconSizeMedium > *:first-child": {
        display: "flex"
      }
    },
    shareData: {
      display: "flex",
      flexDirection: "row"
    },
    platform: {
      color: "#fff",
      fontSize: "26px",
      marginLeft: "10px",
      textTransform: "none"
    },
    termsAndConditions: {
      "& p": {
        marginTop: "0px"
      }
    },
    referHappyFrndsImg: {
      marginTop: "10%",
    },
    referAFriendImg:{
      height: "auto",
      width: "100%"
    }
  })
);
