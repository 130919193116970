const clientName = process.env.REACT_APP_CLIENT_NAME?.trim();
let assetBasePath = process.env.REACT_APP_ASSETS_BASE_PATH?.trim() || "";
let pathName = "veryme";

assetBasePath = assetBasePath?.replace("client", pathName);

export const layoutBackgroundImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/background.jpg`);
export const splashScreenLogo = require(`../../Configurations/Clients/${clientName}/Assets/Images/loaderWhite.svg`);
export const landingScreenLogo = require(`../../Configurations/Clients/${clientName}/Assets/Images/logo@3x.png`);
export const myProfileBgImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/My-Profile-Landing-Page.jpg`);
export const smsPermissionHeroImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/onboardingPermissionsSms.png`);
export const trafficPermissionHeroImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/backphone3.png`);
export const smsPermissionImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/backphone.png`);
export const trafficPermissionNewImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/network.jpg`);
export const trafficImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/traffic.svg`);
export const trafficCircleImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/circle_b.png`);
export const SummaryHeroImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/backphone3.png`);
export const summaryCard1 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card1.png`);
export const summaryCard2 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card2.png`);
export const summaryCard3 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card3.png`);
export const summaryCard4 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card4.png`);
export const profilPermissionImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/onboardingPermissionsProfilingR02Assets@2x.png`);
export const clientLogoPrimary = require(`../../Configurations/Clients/${clientName}/Assets/Images/client-logo-primary.png`);
export const clientLogoSecondary = require(`../../Configurations/Clients/${clientName}/Assets/Images/client-logo-secondary.png`);
export const clientErrorPageLogo = require(`../../Configurations/Clients/${clientName}/Assets/Images/client-error-page-logo.png`);
export const copyIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/group9.png`);
export const giftIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/iconGiftUnopenedSmallWhite.png`);
export const emailIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/group7.png`);
export const whatsappIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/group6.png`);
export const textMessageIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/speech.png`);
export const giftRedIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/iconGiftUnopenedRed.png`);
export const arrowLeftIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/arrowLeftSvg_2@2x.png`);
export const giftIconAll = require(`../../Configurations/Clients/${clientName}/Assets/Images/allRewards@2x.png`);
export const loaderSvg = require(`../../Configurations/Clients/${clientName}/Assets/Images/loader.svg`);
export const timerSvg = require(`../../Configurations/Clients/${clientName}/Assets/Images/timer.svg`);
export const iwIntroGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/instant-win-intro.jpg`);
export const iwWinGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/instant-win-winner.jpg`);
export const iwLooseGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/instant-win-loser.jpg`);
export const iwPopBalloonGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/centre-balloon-bright-single.gif`);
export const homeScreenPrimerImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/homeScreenFilters@2x.png`);
export const homeScreenChristmas = require(`../../Configurations/Clients/${clientName}/Assets/Images/home_primer_christmas.jpg`);
export const iconCloseWhite = require(`../../Configurations/Clients/${clientName}/Assets/Images/icon-close-white.svg`);
export const iconGiftOpenedWhite = require(`../../Configurations/Clients/${clientName}/Assets/Images/iconGiftOpenedWhite@2x.png`);
export const iconLock = require(`../../Configurations/Clients/${clientName}/Assets/Images/lock.png`);
export const iconEmptyBox = require(`../../Configurations/Clients/${clientName}/Assets/Images/empty-box.svg`);
export const iconEmptyBoxBlack = require(`../../Configurations/Clients/${clientName}/Assets/Images/empty-box-black.svg`);
export const pinIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/pin.png`);
export const popupIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/popup.png`);
export const redpinIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/redpin.png`);
export const locationPermissionHeroImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/backphone2.png`);
export const clientFavicon = require(`../../Configurations/Clients/${clientName}/Assets/Images/favicon.ico`);
export const hairdryerIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/hairdryer.png`);
export const notaboneIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/notabone.png`);
export const reallyIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/really.png`);
export const notificationImg = require(`../../Configurations/Clients/${clientName}/Assets/Images/notification.png`);
export const noSummaryCard1 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card1_e.png`);
export const noSummaryCard2 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card2_e.png`);
export const noSummaryCard3 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card3_e.png`);
export const noSummaryCard4 = require(`../../Configurations/Clients/${clientName}/Assets/Images/card4_e.png`);
export const chatOfferIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/allRewardsChat@2x.png`);
export const chatFaqIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/inAppLink@2x.png`);
export const chatExternalLinkIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/externalLink@2x.png`);
export const eatLocalBackground = require(`../../Configurations/Clients/${clientName}/Assets/Images/eatLocalOffer.jpg`);
export const eatLocalGourmetImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/gourmet-society-logo.svg`);
export const eatlocalLogo = require(`../../Configurations/Clients/${clientName}/Assets/Images/eatLocalLogo.png`);
export const waitLoaderSvg = require(`../../Configurations/Clients/${clientName}/Assets/Images/wait_loader.svg`);
export const christmasSpecialBackgroundImg = require(`../../Configurations/Clients/${clientName}/Assets/ChristmasImages/christmasbg.jpg`);
export const vmr_loaderwhite = require(`../../Configurations/Clients/${clientName}/Assets/Images/vmr_loaderwhite.svg`);
export const tickIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/tick.png`);
export const vmrLite_AppIntro_First = require(`../../Configurations/Clients/${clientName}/Assets/Images/vmrLite_AppIntro_1.png`);
export const vmrLite_AppIntro_Second = require(`../../Configurations/Clients/${clientName}/Assets/Images/vmrLite_AppIntro_2.png`);
export const topUpTo_Unlock = require(`../../Configurations/Clients/${clientName}/Assets/Images/topUpIcon.png`);
export const vmrLite_RedIcon = require(`../../Configurations/Clients/${clientName}/Assets/Images/giftedRed.png`);
export const welcome_Background_Img = require(`../../Configurations/Clients/${clientName}/Assets/Images/bitmap.png`);
export const notification_Icon = require(`../../Configurations/Clients/${clientName}/Assets/Images/notificationIcon.svg`);
export const location_Icon = require(`../../Configurations/Clients/${clientName}/Assets/Images/locationIcon.svg`);
export const megaphone_Icon = require(`../../Configurations/Clients/${clientName}/Assets/Images/megaphoneIcon.svg`);
export const luckydip_IntroScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/luckDipIntro_Screen.jpg`);
export const claw_IntroScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/claw-intro-screen.gif`);
export const conveyorbelt_IntroScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/conveyor-belt-intro-screen.gif`);
export const PicktoWin_background = require(`../../Configurations/Clients/${clientName}/Assets/Images/PicktoWin-choose-background.gif`);
export const luckydip_CongratsScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/PicktoWin-Winning-Congrats.gif`);
export const picktoWin_InterimStatic = require(`../../Configurations/Clients/${clientName}/Assets/Images/PicktoWin-Choosing-Winning-InterimStatic.jpg`);
export const visit_To_Win_Bg = require(`../../Configurations/Clients/${clientName}/Assets/Images/V2W-home-background.jpg`);
export const visit_To_Win_Logo = require(`../../Configurations/Clients/${clientName}/Assets/Images/V2W-logo.png`);
export const visit_To_Win_Animated_Heart = require(`../../Configurations/Clients/${clientName}/Assets/Images/V2W-animated-heart.gif`);
export const visit_To_Win_FullScreen_Heart = require(`../../Configurations/Clients/${clientName}/Assets/Images/V2W-fullscreen-heart.gif`);
export const visit_To_Win_HalfScreen_Overlay = require(`../../Configurations/Clients/${clientName}/Assets/Images/V2Win-partial-overlay.gif`);

// Christmas Images - InstantWin
export const iwXmasIntroBg = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-Xmas-bg.jpg`);
export const iwXmasIntroSnowflakes = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-Xmas-Intro-Snowflakes.gif`);
export const iwXmasWinGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-Xmas-Winners.gif`);
export const iwXmasLooseGif = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-Xmas-Losers.gif`);
// Christmas Images - VisitToWin
export const iwVisit_To_Win_HalfScreen_Overlay = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-V2Win-partial-overlay.gif`);
export const iwVisit_To_Win_FullScreen_Heart = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-V2W-fullscreen-heart.gif`);
export const iwVisit_To_Win_Snowflakes_Heart = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-V2W-fullscreen-snowflakes-heart.gif`);
// Christmas Images - LuckyDip
export const iwluckydip_IntroScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-luckDipIntro_Screen.gif`);
export const iwPicktoWin_background = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-PicktoWin-choose-background.gif`);
export const iwPicktoWin_backgroundP2 = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-PicktoWin-choose-background2.gif`);
export const iwluckydip_CongratsScreen = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-PicktoWin-Winning-Congrats.gif`);
export const iwPicktoWin_InterimStatic = require(`../../Configurations/Clients/${clientName}/Assets/Images/iw-PicktoWin-Choosing-Winning-InterimStatic.jpg`);

export const convergedClaimImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/converged-offer-claim.png`);
export const coffeeClubLogoImage = require(`../../Configurations/Clients/${clientName}/Assets/Images/coffee_club_logo.png`);
export const iwIntroNew = require(`../../Configurations/Clients/${clientName}/Assets/Images/instantWinIntroNew.png`);
export const iwWinNew = require(`../../Configurations/Clients/${clientName}/Assets/Images/instant-win-winner-new.png`);
export const iwLoseNew = require(`../../Configurations/Clients/${clientName}/Assets/Images/instant-win-loser-new.png`);
export const loadingScreenBg = require(`../../Configurations/Clients/${clientName}/Assets/Images/VMR_Loading_Screen_new.png`);
export const giftIconSparks = require(`../../Configurations/Clients/${clientName}/Assets/Images/Icon_Gift_Unopened_sparks.png`);
