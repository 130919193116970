import React, { Fragment, useEffect, useState } from "react";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, getImageDetails, iOSDevice } from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import AppConfig from "Models/ReduxModels/AppConfig";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";

interface IProps {
  applicationFlags: IApplication;
  referalConfig?: any;
  userDetails: IUserDetails;
}

const ReferFriends: React.FC<IProps> = props => {
  const { applicationFlags, referalConfig, userDetails } = props;
  const clientName = getClientName();
  const history = useHistory();
  const classes = useStyles();
  const [shareComponent, setShareComponent] = useState(false);
  const [showTermsConditions, setCollapsibleDiv] = useState({
    toggle: false,
    termsAndConditions: ""
  });
  const root = document.getElementById("root");
  const fixedMainElement: any = document.getElementById("main-container");

  useEffect(() => {
    resetScroll();
  }, [shareComponent]);

  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
    if (fixedMainElement) {
      fixedMainElement.scrollTop = 0;
    }
  };

  const redirectToHome = () => {
    history.push("/home");
    new ApplicationFlag({
      id: "",
      isShowFooter: true
    }).$save();
  };

  const goBack = () => {
    if (!shareComponent) {
      window.history.back();
      new ApplicationFlag({
        id: "",
        isShowFooter: true
      }).$save();
    }
    setShareComponent(false);
  };

  const generateUrl = (encodedBody: any, platform: string) => {
    let emailSubject = "";
    referalConfig?.value?.forEach(item => {
      if (item.name === "email_subject") {
        emailSubject = item.value;
      }
    });
    switch (platform) {
      case "Email":
        encodedBody = encodeURIComponent(encodedBody);
        return `mailto:?subject=${emailSubject}&body=` + encodedBody;

      case "Text":
        let separator = iOSDevice() ? "&" : "?";
        encodedBody = encodeURIComponent(encodedBody);
        return "sms:" + separator + "body=" + encodedBody;

      case "Whatsapp":
        encodedBody = encodeURIComponent(encodedBody);
        return "whatsapp://send?text=" + encodedBody;
    }
  };

  const generateRedirectionUrl = (sharingUrl, platform) => {
    let msgCopy = "";
    referalConfig?.value?.forEach(item => {
      if (item.name === "message_copy") {
        msgCopy = item.value;
      }
    });

    let encodedData =
      msgCopy + " " + `${sharingUrl}&channel=${platform.toUpperCase()}`;
    return generateUrl(encodedData, platform);
  };

  const handleShareClick = (e, platform) => {
    e.preventDefault();
    logEvents({
      eventType: EVENT_TYPE.REFERRAL_LINK_PLATFORM,
      ...{
        type: platform
      }
    });
    pushAllEventsToServer();
    let urlFromResponse = userDetails?.referralsData?.referralLink;
    const url: any = generateRedirectionUrl(urlFromResponse, platform);
    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", url);
    linkElement.click();
  };

  const renderShareData = (icon, platform) => {
    return (
      <Button
        className={classes.shareIconButton}
        disableRipple
        disableFocusRipple
        startIcon={
          <div>
            <img src={icon} alt={platform} />
          </div>
        }
        onClick={e => {
          handleShareClick(e, platform);
        }}
      >
        <Typography className={classes.platform}>{platform}</Typography>
      </Button>
    );
  };

  const renderSharePage = () => {
    return (
      <div className={classes.referContainer}>
        <div className={classes.bgImgContainer}>
          <p className={classes.shareText}>
            <strong style={{ fontSize: "34px" }}>Share</strong> your
            <strong style={{ fontSize: "36px" }}> REFERRAL </strong> link by:
          </p>
          <div className={classes.shareData}>
            <div style={{ flex: "1" }}>
              {renderShareData(ImagesMapping[clientName].shareChatIcon, "Text")}
              {renderShareData(
                ImagesMapping[clientName].shareEmailIcon,
                "Email"
              )}
              {renderShareData(
                ImagesMapping[clientName].shareCallIcon,
                "Whatsapp"
              )}
            </div>
            <div>
              <img
                alt="giftIcon2"
                src={ImagesMapping[clientName].giftIconSparks}
                className={classes.referGiftIcon}
              />
            </div>
          </div>
        </div>
        <div className={classes.referHappyFrndsImg}>
          <img
            src={ImagesMapping[clientName].referalHappyfrds}
            alt="happyFriends"
            className={classes.referAFriendImg}
          />
        </div>
      </div>
    );
  };

  const handleClick = e => {
    logEvents({
      eventType: EVENT_TYPE.SHARE_REERRAL_LINK
    });

    pushAllEventsToServer();
    setShareComponent(true);
  };

  const handleTermsConditions = e => {
    let termsAndConditions = "";
    {
      referalConfig?.value?.forEach(item => {
        if (item.name === "terms_and_conditions") {
          termsAndConditions = item.value;
        }
      });
    }
    setCollapsibleDiv({
      toggle: !showTermsConditions.toggle,
      termsAndConditions: termsAndConditions
    });
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <Header
          headerText=""
          isHeaderInverted={true}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        <div className={classes.mainWrapper}>
          {shareComponent && renderSharePage()}
          {!shareComponent && (
            <>
              <div className={classes.referContainer}>
                <div className={classes.bgImgContainer}>
                  <div className={classes.referHeading}>
                    <h1>
                      <span style={{ fontSize: "34px" }}>Refer a friend</span>
                      <text style={{ fontWeight: "100", display: "block" }}>
                        and get
                      </text>
                      <span style={{ fontSize: "36px" }}>REWARDED</span>
                    </h1>
                    <img
                      alt="giftIcon"
                      src={ImagesMapping[clientName].giftIconSparks}
                      className={classes.referGiftIcon}
                    />
                  </div>
                  <p className={classes.referSmallText}>
                    Share the joy of weekly treats, discounts and exclusive
                    giveaways on Vodafone’s VeryMe Rewards.
                  </p>
                  <div className={classes.referalCountBox}>
                    Completed referrals{" "}
                    <span style={{ flex: "1", textAlign: "center" }}>
                      {userDetails?.referralsData?.closedReferrals}
                    </span>
                  </div>
                </div>
                <p className={classes.referText}>
                  To participate, you need to be a Pay monthly customer or Pay
                  as you go customer and you’ve topped up at least £10 credit on
                  your account in the last six weeks. For each friend you refer,
                  both you and your friend are entitled to 1 reward each.
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "0px",
                    marginBottom: "8px"
                  }}
                  onClick={handleTermsConditions}
                >
                  Terms and Conditions &nbsp;
                  {showTermsConditions.toggle ? "-" : "+"}
                </p>
                {showTermsConditions.toggle && (
                  <Typography
                    className={classes.termsAndConditions}
                    dangerouslySetInnerHTML={{
                      __html: showTermsConditions.termsAndConditions
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {!shareComponent && (
          <div className={classes.referalLinkButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.referalLinkButton}
              onClick={handleClick}
            >
              Share my link
            </Button>
          </div>
        )}
      </Fragment>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let referalConfig = AppConfig.getInsatnce("referral-section")?.props;
  let userDetails = UserDetails.getInsatnce("")?.props;

  return { applicationFlags, referalConfig, userDetails };
};

export default connect(mapStateToProps)(ReferFriends);
