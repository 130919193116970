import React, { Fragment, useState, useEffect } from "react";
import { useStyles } from "./styles";
import arrow from "../../../Configurations/Clients/vodafone/Assets/Images/arrowLeftSvg_2@2x.png";
import Chip from "@material-ui/core/Chip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from "gce-cxinteracts-ui-components-library";
import Interests from "../../../Models/ReduxModels/Interests";
import { connect } from "react-redux";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import { useHistory } from "react-router-dom";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import UserProfile from "../../../Models/ReduxModels/UserProfileModel";
import {
  isoToLocalDateConversion,
  getImageDetails,
  convertLocalDateToISO,
  getClientName,
  previousPages,
  loadEvents
} from "../../../Utils/GeneralUtils";
import { getInterestsData, sanitizeInterestData } from "Utils/InterestUtil";
import UserDetails from "../../../Models/ReduxModels/UserDetails";
import { updateProfileApiCall } from "../../../Services/ProfileService";
import { logEvents, EVENT_TYPE } from "Services/EventService";
import { omit } from "lodash";
import { Button, Typography } from "@material-ui/core";
import moment from "moment";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  clientData: any;
  userProfileDetails: any;
  interests: any;
  userDetails: any;
}

const MyProfile: React.FC<IProps> = props => {
  let classes = useStyles();
  const history = useHistory();
  const { clientData, userProfileDetails, interests, userDetails } = props;
  const [selectedInterests, setSelectedInterests] = useState([]);
  let accessToken = clientData?.token;
  const clientName = getClientName();
  var bg = ImagesMapping[clientName].myProfileBgImg;
  let userSelectedInterests = userProfileDetails.interests;
  let maxDate = moment().subtract(18, "years").format("MMMM DD YYYY, h:mm:ss");
  let newMaxDate = new Date(maxDate);

  useEffect(() => {
    let userInterests: any = [];
    Object.keys(interests).map(function (key) {
      return (userSelectedInterests || []).map((item: any) => {
        if (interests[key].id === item) {
          userInterests.push(interests[key]);
        }
      });
    });
    setSelectedInterests(userInterests);
  }, []);

  let firstFiveSelectedInterest = selectedInterests.slice(0, 5);

  const updateProfileDob = async (date: any) => {
    var isoDateTime = convertLocalDateToISO(date);
    const profilePayload = {
      ...omit(userProfileDetails, ["id", "isFirstUse"]),
      interests: getInterestsData(userProfileDetails.interests),
      firstUse: false,
      dob: isoDateTime,
      selfReferralPending: userProfileDetails.selfReferralPending
    };
    const resp = await updateProfileApiCall(profilePayload);
    if (resp?.data?.updateProfile) {
      const { firstUse, dob, interests, ...rest } = resp?.data?.updateProfile;
      new UserProfile({
        id: "",
        ...rest,
        isFirstUse: firstUse,
        dob: dob,
        interests: sanitizeInterestData(interests)
      }).$save();
    } else {
      console.log("Something went wrong !!");
    }
    logEvents({ eventType: EVENT_TYPE.SET_DOB });
  };

  const handleInterests = () => {
    history.push(`/interest/1`);
  };

  const renderMyInterests = () => {
    return (
      <div className={classes.sectionWrapper}>
        <div className={classes.interestContent}>
          <h2 className={classes.heading}>
            {ConstantMapping[clientName].MY_INTEREST_TEXT}
          </h2>
          <button
            className={classes.seeAllButton}
            aria-label="All interests"
            onClick={handleInterests}
          >
            <div className={classes.interestLinkText}>
              {ConstantMapping[clientName].MY_INTEREST_LINK_TEXT}
            </div>
            <div>
              <img
                alt="arrow"
                src={arrow}
                className={classes.interestsLinkArrow}
              />
            </div>
          </button>
        </div>
        <div className={classes.interestChips}>
          {(firstFiveSelectedInterest || []).map((item: any) => {
            return (
              <Chip
                key={item.name}
                label={item.name}
                className={classes.chip}
              />
            );
          })}
          {selectedInterests?.length > 5 ? (
            <span className={classes.pixelText}>...</span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const onClickDateEvent = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "link",
        event_label: "Edit Birthday",
        page_name: ConstantMapping[clientName].TEALIUM_MANUAL_ADD_PROFILE_PAGE,
        same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
    let url = `external:${ConstantMapping[clientName].EDIT_DOB_EXTERNAL_LINK}`;
    window.open(`${url}`, "_blank");
  };

  const CustomInput = props => {
    const { onClick, value } = props;
    const buttonLabel = value
      ? ConstantMapping[clientName].EDIT_TEXT
      : ConstantMapping[clientName].ADD_DOB_TEXT;
    return (
      <Typography
        variant="subtitle1"
        onClick={onClick}
        className={classes.customDateInput}
      >
        <div className={classes.dateInput}>
          <span>{value}</span>
          <button className={classes.buttonDefault} aria-label={buttonLabel}>
            <span className={classes.editDob} onClick={onClickDateEvent}>
              {buttonLabel}
            </span>
          </button>
        </div>
      </Typography>
    );
  };

  const renderDatePicker = () => {
    return (
      <div className={classes.birthDate}>
        <DatePicker
          selected={
            userProfileDetails?.dob
              ? isoToLocalDateConversion(userProfileDetails?.dob)
              : null
          }
          onChange={updateProfileDob}
          popperPlacement="top"
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInput />}
          showFullMonthYearPicker
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          withPortal
          maxDate={newMaxDate}
          scrollableYearDropdown
          disabled
        />
      </div>
    );
  };

  const renderBirthday = () => {
    return (
      <div className={classes.sectionWrapper}>
        <h2 className={classes.heading}>
          {ConstantMapping[clientName].MY_BIRTHDAY_TEXT}
        </h2>
        <div className={classes.birthdayContent}>
          {!userProfileDetails || !userProfileDetails.dob ? (
            <p className={classes.dataPermissionDesc}>
              {ConstantMapping[clientName].ENTER_BIRTHDATE_TEXT}
            </p>
          ) : (
            ""
          )}
          <div className={classes.birthdayValidation}>
            <>
              {renderDatePicker()}
              <p className={classes.birthdateText}>
                {ConstantMapping[clientName].BIRTHDAY_VALIDATION_TEXT}
              </p>
            </>
          </div>
        </div>
      </div>
    );
  };

  const linkClick = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "link",
        event_label: "Review Permissions",
        page_name: ConstantMapping[clientName].TEALIUM_MANUAL_ADD_PROFILE_PAGE,
        same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
  };

  const renderRewards = () => {
    return (
      <div
        className={classes.rewardsWrapper}
        style={{
          backgroundImage: "url(" + bg + ")"
        }}
        title="Reward code used background"
      >
        <h2 className={classes.rewardsCount}>
          {userDetails.totalClaimedOffers}
        </h2>
        <p>{ConstantMapping[clientName].REWARDS_USED_TEXT}</p>
      </div>
    );
  };
  const redirectToHome = () => {
    history.push("/home");
  };

  const handleExternalLinkClick = () => {
    let url = `external:${ConstantMapping[clientName].CON_PROFILE_EXTERNAL_LINK}`;
    window.open(`${url}`, "_blank");
  };

  const renderConvergedSection = () => {
    return (
      <>
        <div className={classes.convergedContainer}>
          <div className={classes.convergedLeftSection}>
            <span className={classes.convergedText}>Vodafone Together</span>
            <div className={classes.convergedButtonContainer}>
              <Button
                className={classes.findOutMore}
                variant="contained"
                onClick={handleExternalLinkClick}
              >
                Find out more
              </Button>
            </div>
          </div>
          <img
            src={ImagesMapping[clientName].myprofile_converged_user}
            alt="converged"
            className={classes.convergedRightSection}
          />
        </div>
        <div className={classes.divider}></div>
      </>
    );
  };

  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={() => window.history.back()}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {renderRewards()}
          {renderMyInterests()}
          <div className={classes.divider}></div>
          {userDetails?.isConvergedUser ? renderConvergedSection() : ""}
          {renderBirthday()}
          <div className={classes.divider}></div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state).props;
  let interests = Interests.getInsatnce("", state).props;
  let userProfileDetails = UserProfile.getInsatnce("", state).props;
  let userDetails = UserDetails.getInsatnce("", state).props;

  return {
    clientData,
    userProfileDetails,
    interests,
    userDetails
  };
};

export default connect(mapStateToProps)(MyProfile);
