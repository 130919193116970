import { BaseModel } from "ReduxStore/index";

export interface ProfilePermission {
  sms: boolean;
  call: boolean;
  email: boolean;
  profiling: boolean;
  traffic: boolean;
  location: boolean;
  seeAllOffers?: boolean;
}

export interface UserProfileDetails {
  isFirstUse: boolean;
  interests: number[];
  permissions: ProfilePermission;
  dob: string;
  currentOnboardingPage?: number;
  totalOnboardingPages?: Array<string>;
  selfReferralPending?: boolean;
}

export default class UserProfile extends BaseModel<UserProfileDetails> {
  static resource = "UserProfile";
}
